


















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class SmallCard extends Vue {

  @Prop({ default: '' }) private header!: string;
}
