














































































































import { Component, Vue } from 'vue-property-decorator';
import CaoSelector from '@/components/tool/CaoSelector.vue';
import FinalDetails from '@/components/tool/FinalDetails.vue';
import { ICao } from '@/interfaces/cao';
import { ISalaryTable } from '@/interfaces/salaryTable';
import { ToolResult } from '@/interfaces/toolResult';
import { Action, Getter, Mutation } from 'vuex-class';
import { AccountState } from '@/store/account/types';
import VButton from '@/components/VButton.vue';
import SmallCard from '@/components/cards/SmallCard.vue';
import FormField from '@/utilities/validation/formfield';
import ValidatedForm from '@/utilities/validation/validatedform';
import * as accountTypes from '@/store/account/types';

@Component({
  components: {
    CaoSelector,
    FinalDetails,
    VButton,
    SmallCard,
  },
})
export default class UitzendBevestiging extends Vue {

  @Getter('lastToolResult', { namespace: 'main' }) private lastToolResult!: ToolResult;
  @Action('profileImage', { namespace: 'account' }) private getProfileImage!: accountTypes.ProfileImageAction;

  private toolResult: ToolResult = new ToolResult();
  private submitted = false;

  private uitzendForm: ValidatedForm = new ValidatedForm({
    name: new FormField((value: any, dirty: boolean) => value !== '', ''),
    street: new FormField((value: any, dirty: boolean) => value !== '', ''),
    postal: new FormField((value: any, dirty: boolean) => value !== '', ''),
    city: new FormField((value: any, dirty: boolean) => value !== '', ''),
    start: new FormField((value: any, dirty: boolean) => value !== '', ''),
    end: new FormField((value: any, dirty: boolean) => value === '' ? null : true, ''),
    employerName: new FormField((value: any, dirty: boolean) => value !== '', ''),
    employerStreet: new FormField((value: any, dirty: boolean) => value !== '', ''),
    employerPostal: new FormField((value: any, dirty: boolean) => value !== '', ''),
    employerCity: new FormField((value: any, dirty: boolean) => value !== '', ''),
    phone: new FormField((value: any, dirty: boolean) => value !== '', ''),
    department: new FormField((value: any, dirty: boolean) => value !== '', ''),
    reportTo: new FormField((value: any, dirty: boolean) => value !== '', ''),
  });

  get uitzendFormValid() { return this.uitzendForm.fieldValidity(); }

  private created() {
    this.toolResult = this.lastToolResult;
    this.getProfileImage(); // Load profile image into store so FinaLDetails can rendere immediately
  }

  private submit() {
    if (this.uitzendForm.formValid()) {
      this.submitted = true;
    }
  }

}
