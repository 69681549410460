




















import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import * as types from '@/store/main/types';
import * as accountTypes from '@/store/account/types';
import { ToolResult } from '@/interfaces/toolResult';
import VButton from '@/components/VButton.vue';
import { Action } from 'vuex-class';
import ValidatedForm from '@/utilities/validation/validatedform';
import { getCurrentDate, toReadableDateFormat } from '@/utilities/filters';
import getUitzendbevestigingTemplate from '@/pdf-templates/uitzendbevestiging';

@Component({
  components: { VButton },
})
export default class FinalDetails extends Vue {

  @Prop({ default: new ToolResult() }) private toolResult!: ToolResult;
  @Prop() private uitzendDetails!: ValidatedForm;
  @Action('getPdf', { namespace: 'main' }) private getPdf!: types.GetPdfAction;
  @Action('profileImage', { namespace: 'account' }) private getProfileImage!: accountTypes.ProfileImageAction;

  private submitting = false;
  private iCaoText = '';
  private imageSrc = '';
  private invDate = toReadableDateFormat;

  private created() {
    this.getProfileImage().then((response) => {
      this.imageSrc = response;
      this.iCaoText = getUitzendbevestigingTemplate(this.toolResult, this.imageSrc, this.uitzendDetails);
    });
  }

  private submit() {
    this.submitting = true;
    this.getPdf({ html: this.iCaoText, filename:
    `${ this.uitzendDetails.fields.name.value }_` +
     `${ this.uitzendDetails.fields.employerName.value }_` +
     `${ this.toolResult.cao.readable_name_year }_` +
     `${ getCurrentDate() }`,
    }).then((link) => {
      link.click();
    })
      .finally(() => this.submitting = false);
  }

  @Watch('uitzendDetails.fields')
  @Watch('toolResult')
  private onDetailsChanged(oldValue: any, newValue: any) {
    this.iCaoText = getUitzendbevestigingTemplate(this.toolResult, this.imageSrc, this.uitzendDetails);
  }


}
