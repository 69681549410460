import { ToolResult } from '@/interfaces/toolResult';
import ValidatedForm from '@/utilities/validation/validatedform';
import { yesNo, toReadableDateFormat } from '@/utilities/filters';

const getUitzendbevestigingTemplate = (toolResult: ToolResult, imageSrc: string , uitzendDetails: ValidatedForm) => {

  const advEuros = toolResult.adv ? toolResult.adv_perc * 0.01 * toolResult.salary : 0;
  const yearEndBonusEuros = toolResult.showYearEndBonus ? (
    (parseFloat(toolResult.cao.year_end_bonus_amount)) * 0.01 * (toolResult.salary + advEuros)
    ) : 0;
  const brutoHourlySalary = toolResult.salary + advEuros + yearEndBonusEuros;

  const advDetails = toolResult.adv ? `
      <tr><td>ADV</td><td>${ toolResult.adv_perc }% - ${
        (advEuros).toFixed(2)
      }</td></tr>` : `<tr><td>ADV %</td><td>${ toolResult.adv_perc}</td></tr>`;

  const endYearBonusDetails = toolResult.showYearEndBonus ? `
      <tr>
        <td>Eindejaarsuitkering ${ toolResult.cao.year_end_bonus_amount }%</td>
        <td>&euro; ${(yearEndBonusEuros).toFixed(2)}</td>
      </tr>
    ` : '';

  return `
  <html>
    <head>
      <style>
        body {
          font-size: 19px;
          font-family: 'Open sans', sans-serif;
        }
        .caotext strong {
          display: block;
          border-bottom: solid 1px black;
        }
        th {
          text-align: left;
          border-bottom: solid 1px black;
        }
        .blueHeader {
          font-size: 25px;
          color: #1FA1E6;
          font-weight: bold;
          margin-bottom: 20px;
          width: 100%;
        }
        .page-break {
          page-break-before: always;
        }
        table, tr, td, th, tbody, thead, tfoot {
          page-break-inside: avoid !important;
        }
        table {
          width: 100%;
          border-color: white;
          border-style: none !important;
          border-spacing: 0px !important;
          border-collapse: collapse !important;
        }
        .company-logo {
          width: 100%;
          height: 80px;
        }
        .company-logo img {
          height: auto;
          max-width: 240px;
          max-height: 240px;
          float: right;
        }
        table td, table th {
          padding: 8px !important;
        }
        table th {
          padding-bottom: 4px !important;
        }
        .caotext table td {
          border: 1px solid black;
        }
        .caotext table tr:first-child td {
          border-top: 0;
        }
        .caotext table tr td:first-child {
          border-left: 0;
        }
        .caotext table tr:last-child td {
          border-bottom: 0;
        }
        .caotext table tr td:last-child {
          border-right: 0;
        }
      </style>
    </head>
    <body>
    <div class="company-logo">
    <img src="${ imageSrc }" />
    </div>
    <br />
    <div class="blueHeader">Uitzendbevestiging ${ uitzendDetails.fields.name.value }</div>
    <table><tr><th colspan="2">Details uitzendkracht</th></tr>
    <tr><td width="30%">Naam</td><td>${ uitzendDetails.fields.name.value }</td></tr>
    <tr><td>Adres</td><td>${ uitzendDetails.fields.street.value }</td></tr>
    <tr><td>Postcode</td><td>${ uitzendDetails.fields.postal.value} ${ uitzendDetails.fields.city.value }</td></tr>
    <tr><td>Startdatum</td><td>${ uitzendDetails.fields.start.value }</td></tr>
    <tr>
      <td>Einddatum</td>
      <td>${ uitzendDetails.fields.end.value === '' ? 'Geen' : uitzendDetails.fields.end.value }</td>
    </tr>
    </table>
    <p></p>
    <table><tr><th colspan="2">Details Opdrachtgever</th></tr>
    <tr><td width="30%">Naam</td><td>${ uitzendDetails.fields.employerName.value }</td></tr>
    <tr><td>Adres</td><td>${ uitzendDetails.fields.employerStreet.value }</td></tr>
    <tr>
      <td>Postcode</td>
      <td>${ uitzendDetails.fields.employerPostal.value } ${ uitzendDetails.fields.employerCity.value }</td>
    </tr>
    <tr><td>Telefoon</td><td>${ uitzendDetails.fields.phone.value }</td></tr>
    <tr><td>Afdeling</td><td>${ uitzendDetails.fields.department.value }</td></tr>
    <tr><td>Melden bij</td><td>${ uitzendDetails.fields.reportTo.value }</td></tr>
    </table>
    <p></p>
    <table><tr><th colspan="2">Cao details</th></tr>
    <tr><td width="30%">Cao</td><td>${ toolResult.cao.readable_name }</td></tr>
    <tr>
    <td>Looptijd</td>
    <td>
    ${ toReadableDateFormat(toolResult.cao.duration_start) } - ${ toReadableDateFormat(toolResult.cao.duration_end) }
    </td>
    </tr>
    <tr><td>
      Aangemeld bij Ministerie
    </td>
    <td>
    ${ toolResult.cao?.cao_entry_date === null ? 'Nee' : toReadableDateFormat(toolResult.cao.cao_entry_date!) }
    </td></tr>
    <tr><td>AVV</td><td>${ yesNo(toolResult.cao.avv) }</td></tr>
    <tr><td>ADV</td><td>${ yesNo(toolResult.adv) }</td></tr>
    <tr><td>Eindejaarsuitkering</td><td>${ yesNo(toolResult.showYearEndBonus) }</td></tr>
    <tr><td>ABU/NBBU</td><td>${ yesNo(toolResult.abuNbbu) }</td></tr>
    <tr><td>Salaristabel</td><td>${ toolResult.salaryTable.name }</td></tr>
    <tr><td>Functie</td><td>${ toolResult.job !== null ? toolResult.job.name : '' }</td></tr>
    <tr><td>Functieschaal</td><td>${ toolResult.payscale }</td></tr>
    <tr><td>Periodiek</td><td>${ toolResult.periodical }</td></tr>
    <tr><td>Geboortedatum</td><td>${ toolResult.birthdate }</td></tr>
    <tr><td>Laatste update</td>
    <td>
      ${ toReadableDateFormat(toolResult.salaryTable.active_per) } ${ toolResult.salaryTable.active_per_description }
    </td>
    </tr>
    <tr><td>Basisloon</td><td>€ ${ toolResult.salary }</td></tr>
    ${ advDetails }
    ${ endYearBonusDetails }
      <tr><td>Bruto uurloon</td><td>€ ${
        (brutoHourlySalary).toFixed(2)
      }</td></tr>
    </table>
    <div class="blueHeader page-break">Text uit cao</div>
    <p></p><div class="caotext">`
    + toolResult.cao.website_text +
    `
    </div>
    </body>
    </html>
  `;
};

export default getUitzendbevestigingTemplate;
